<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2025-03-01 11:40:52
 * @LastEditTime : 2025-03-11 09:41:07
 * @LastEditors  : BigBigger
-->
<template>
  <div class="pop">
    <div class="pop-title">
      退费申请
    </div>
    <div class="pop-body">
      <div class="pop-card">
        <div class="card-title">报名信息</div>
        <p>
          姓名：{{ refundInfo.realName }}
        </p>
        <p>
          电话：{{ refundInfo.phone }}
        </p>
        <p>
          课程名称：{{ refundInfo.courseGroupName }}
        </p>
        <p>
          订单编号：{{ refundInfo.orderCode }}
        </p>
      </div>
      <div class="pop-card" v-if="$route.query.courseType != 3">
        <div class="card-title">岗位信息</div>
        <p>
          部门名称：{{ refundInfo.departmentName }}
        </p>
        <p>
          单位名称：{{ refundInfo.employer }}
        </p>
        <p>
          职位名称：{{ refundInfo.positionName }}
        </p>
        <p>
          职位代码：{{ refundInfo.jobCode }}
        </p>
        <p>
          招考人数：{{ refundInfo.numberCandidates }}
        </p>
      </div>
      <div class="pop-card" v-if="!refundStatus || refundStatus === 4">
        <van-form ref="form">
          <div class="card-title"><span style="color: #ee0a24;">*</span>上传拟录用公示名单图片</div>
          <van-field name="publicityPicture" :rules="[{ required: true, message: '请上传拟录用公示名单图片' }]">
            <template #input>
              <v-upload v-model="model.publicityPicture" :maxSize="10485760" />
            </template>
          </van-field>
          <template v-if="$route.query.backPromiseType == 2">
            <div class="card-title"><span style="color: #ee0a24;">*</span>上传退费承诺书图片</div>
            <van-field name="backPromiseType" :rules="[{ required: true, message: '请上传退费承诺书图片' }]">
              <template #input>
                <v-upload v-model="model.backPromise" :maxSize="10485760" />
              </template>
            </van-field>
          </template>
          <van-field required v-model="model.refundMoney" type="number" label="退款金额" name="refundMoney"
            placeholder="退款金额" :rules="[{ required: true, message: '请输入退款金额' }]" />
          <van-field required v-model="model._type" label="退款方式" name="_type" placeholder="退款方式"
            :rules="[{ required: true, message: '请选择退款方式' }]">
            <template #input>
              <van-radio-group v-model="model._type" direction="horizontal" @change="clearForm">
                <van-radio v-if="[1, 3].includes(refundType)" name="1">支付宝</van-radio>
                <van-radio v-if="[2, 3].includes(refundType)" name="2">银行卡</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="model._type == 2">
            <van-field required v-model="model.bankUsername" label="持卡人" name="bankUsername" placeholder="持卡人"
              :rules="[{ required: true, message: '请输入持卡人' }]" />
            <van-field required v-model="model.bankDeposit" label="开户行" name="bankDeposit" placeholder="开户行"
              :rules="[{ required: true, message: '请输入开户行' }]" />
            <van-field required v-model="model.refundAccount" label="银行卡号" name="refundAccount" placeholder="银行卡号"
              :rules="[{ required: true, message: '请输入银行卡号' }]" />
          </template>
          <template v-if="model._type == 1">
            <van-field required v-model="model.zfbUsername" name="zfbUsername" label="支付宝户名" placeholder="支付宝户名"
              :rules="[{ required: true, message: '请输入支付宝户名' }]" />
            <van-field required v-model="model.zfbAccount" name="zfbAccount" label="支付宝账号" placeholder="支付宝账号"
              :rules="[{ required: true, message: '请输入支付宝账号' }]" />
          </template>
          <van-field v-model="model.remark" label="备注" name="remark" placeholder="备注（选填）" />
        </van-form>
      </div>
      <div class="pop-card" v-else>
        <div class="card-title">退款信息</div>
        <p>
          拟录用公示名单：<van-image :src="refundInfo.publicityPicture" height="80" width="80" contain
            @click="preview(refundInfo.publicityPicture)"></van-image>
        </p>
        <p v-if="refundInfo.backPromise">
          退费承诺书：&nbsp;&nbsp;&nbsp;&nbsp;<van-image :src="refundInfo.backPromise" height="80" width="80" contain
            @click="preview(refundInfo.backPromise)"></van-image>
        </p>
        <p>
          退款金额：{{ refundInfo.refundMoney }}
        </p>
        <template v-if="refundInfo.zfbAccount">
          <p>
            支付宝户名：{{ refundInfo.zfbUsername }}
          </p>
          <p>
            支付宝账号：{{ refundInfo.zfbAccount }}
          </p>
        </template>
        <template v-if="refundInfo.refundAccount">
          <p>
            持卡人：{{ refundInfo.bankUsername }}
          </p>
          <p>
            开户行：{{ refundInfo.bankDeposit }}
          </p>
          <p>
            银行卡账号：{{ refundInfo.refundAccount }}
          </p>
        </template>
        <p>
          备注：{{ refundInfo.remark }}
        </p>
      </div>
    </div>
    <div v-if="!refundStatus || refundStatus === 4" class="pop-btn">
      <div @click="submit">提交</div>
    </div>
    <div v-else class="pop-btn">
      <div @click="$router.back()">返回</div>
    </div>
  </div>
</template>

<script>

import { orderRefundDetail, orderRefundSubmit, refundWay } from '@/api/api';
import { ImagePreview, Toast } from 'vant';
export default {
  data() {
    return {
      model: {},
      refundInfo: {},
      refundType: 0,
      loading: false,
      refundStatus: -1,
    };
  },
  mounted() {
    this.getOrderRefundDetail();
  },
  methods: {
    clearForm() {
      this.model.refundAccount = undefined;
      this.model.bankUsername = undefined;
      this.model.bankDeposit = undefined;
      this.model.zfbAccount = undefined;
      this.model.zfbUsername = undefined;
    },
    submit() {
      this.$refs.form.validate().then(() => {
        const t = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        });
        // 删除未选择类型的数据，视情况是否需要
        if (this.model._type === 1) {
          this.model.refundAccount = undefined;
          this.model.bankUsername = undefined;
          this.model.bankDeposit = undefined;
        }
        else if (this.model._type === 2) {
          this.model.zfbAccount = undefined;
          this.model.zfbUsername = undefined;
        }
        this.model._type = undefined;
        orderRefundSubmit(this.model)
          .then((res) => {
            if (res.returnCode !== '10001') {
              Toast.fail('提交失败');
              return;
            }
            Toast.success('退款申请成功');
            this.model = {};
            this.$router.back()
          }).finally(() => {
            t.clear();
          });
      });
    },
    preview(src) {
      ImagePreview([src])
    },
    async getOrderRefundDetail() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      const params = {
        orderId: this.$route.params.orderId,
      };
      await refundWay(params).then(res => {
        this.refundType = res.data.type
      })
      await orderRefundDetail(params).then((result) => {
        this.refundInfo = result.data;
        this.refundStatus = result.data.refundStatus
        this.model = {
          orderId: this.$route.params.orderId,
          publicityPicture: result.data.publicityPicture,
          refundAccount: result.data.refundAccount,
          zfbAccount: result.data.zfbAccount,
          refundMoney: result.data.refundMoney,
          remark: result.data.remark,
          bankUsername: result.data.bankUsername,
          bankDeposit: result.data.bankDeposit,
          zfbUsername: result.data.zfbUsername,
          backPromise: result.data.backPromise,
          _type: this.refundType === 3 ? result.data.refundAccount ? 2 : 1 : this.refundType
        };
      })
      Toast.clear();
    },
  }
};
</script>

<style lang="less" scoped>


.pop {
  font-size: 16px;
  background-color: #F8F8F8;

  .pop-title {
    font-size: 18px;
    line-height: 3;
    font-weight: bold;
    text-align: center;
  }

  .pop-body {
    padding: 10px;
  }

  .pop-card {
    background-color: #fff;
    padding: 0.2rem 4%;
    background: #fff;
    margin-bottom: 0.3rem;
    border-radius: 5px;

    .card-title {
      font-weight: bold;

      .tips {
        color: #999
      }
    }

    p {
      word-break: break-all;
      color: #333333;
      margin: 0.1rem 0;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 0.4rem;
      display: flex;
      align-items: center;
    }

    & + & {
      margin-top: 10px;
    }
  }

  .pop-btn {
    position: sticky;
    background-color: #F8F8F8;
    bottom: 0;
    padding: 0.2rem 3%;

    div {
      width: 100%;
      box-sizing: border-box;
      height: 0.8rem;
      line-height: 0.8rem;
      background: linear-gradient(90deg, #F15F32, #F13232);
      border-radius: 10px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }

  }
}

::v-deep .van-uploader__wrapper {
  margin: 0;

  .van-uploader__upload {
    margin: 0;
  }
}

.van-cell {
  padding-top: 16px;
  padding-bottom: 16px;

  &::after {
    display: none;
  }
}
</style>
<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2025-03-09 13:40:07
 * @LastEditors  : BigBigger
-->
<!--我的订单-->
<template>
  <div>
    <div class="container-bg">
      <div class="order-list"  v-for="(item, index) of orderList" :key="index">
        <div class="top">
          <p>订单号：{{item.orderCode}}</p>
          <span>订单{{item.orderStatus | orderCompleteFilter}}</span>
        </div>
        <div class="order-detail">
          <div class="order-item">
            <p class="title">{{item.courseGroupName}}</p>
            <span class="money">￥{{item.payMoney}}</span>
          </div>
          <!-- <div class="order-item">
            <p>上课形式： {{item.categoryName}}{{item.classWay}}</p>
            <span :class="item.confirmStatus | classFilter">{{item.confirmStatus | confirmStatusFilter}}</span>
          </div> -->
          <div class="order-item">
            <p>开课时间：{{item.classTime}}</p>
          </div>
          <div class="order-item">
            <span style="color: red;font-size: 12px;" v-if="item.refundStatus === 4">退回原因：{{ item.reasonReturn }}</span>
            <span style="color: red;line-height: 1.5;" v-if="[1,2].includes(item.refundStatus)">7 个工作日内复查核对后办理退款</span>
          </div>
        </div>
        <div class="bottom">
          <p>
            {{item.createTime}}
          </p>
          <div class="btn-box">
            <span class="cancelOrder cursor color-red" v-if='getPayBtn(item)' @click="toPay(item)">立即支付</span>
            <span class="cancelOrder cursor" @click="cancelOrder(item)" v-if='item.type === 1 && item.confirmStatus===1 && item.orderStatus === 1'>取消订单</span>
            <div v-if="item.refundSwitch === 2 && item.orderStatus === 2" class="btn" @click="refund(item)">
              <div class="text-red border" v-if="!item.refundStatus || item.refundStatus === 4">申请退费</div>
              <div class="text-blue" v-else-if="item.refundStatus === 1">
                退费已提交
              </div>
              <div div class="text-blue" v-else-if="item.refundStatus === 2">
                退费已受理
              </div>
              <div class="text-red" v-else-if="item.refundStatus === 3">
                退费完成
              </div>
            </div>
            <span class="cursor" @click="jumpOrderDetail(item)">查看详情</span>
            <!-- <span>取消订单</span>
            <span>查看详情</span> -->
          </div>
        </div>
      </div>
      <van-empty v-if="orderList.length === 0" description="暂无数据" />
    </div>  
  
  </div>
</template>
<script>
import { getOrderList, cancelOrder } from '@/api/api'
import RefundButton from './refund.vue';

export default {
    name:'orders',
    data(){
      return{
        page: 1,
        limit: 5,
        total: 0,
        orderList:[],
      }
    },
    components:{
      RefundButton
    },
    mounted(){
      this.getOrderList()
    },
    methods:{
      refund(item) {
        this.$router.push({name: 'orderRefund', params: {orderId: item.id}, query: { courseType: item.type, backPromiseType: item.backPromiseSwitch }})
      },
      toPay(item) {
        this.$router.push({path: `/course/signup/review?&orderId=${item.id}`})
      },
      getPayBtn(item) {
        let state = false
        if(item.type === 1) {
          if(item.confirmStatus === 2) {
            state = item.orderStatus === 1 ? true : false
          } else {
            state = false
          }
        } else {
          state = item.orderStatus === 1 ? true : false
        }
        return state
      },
      pageChange(page) {
        this.page = page
        this.getOrderList();
      },
      async cancelOrder(val) {
        let params = {
          orderId: val.id
        }
        //let response = await this.$axios.post(cancelOrder, null, {params: params})
        let response = await cancelOrder(params)
        if(+response.returnCode !== 10001) {
          this.$toast(response.returnMsg)
          return false
        }
        this.$toast(response.returnMsg)
        this.page = 1;
        this.getOrderList()
      },
      jumpOrderDetail(val) {
        this.$router.push({path: `/mine/orderDetail?id=${val.id}`})
      },
      // 获取订单
      async getOrderList() {
        const params = {
            page: this.page,
            limit: this.limit
        }
        let response = await getOrderList(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.orderList = response.data
        this.total = response.total
      },
    },
    filters: {
      payStatusFilter(val) {
        if(val===1) {
            return '未支付'
        } else if(val===2) {
            return '已支付'
        }
      },
      confirmStatusFilter(val) {
        if(val===1) {
          return '待审核'
        } else if(val===2) {
          return '已审核'
        }else if(val===3){
          return '等待'
        }else if(val===4){
          return '拒绝'
        }else if(val===5){
          return '退班'
        }
      },
      orderStatusFilter(val) {
        if(val===1) {
          return '未支付'
        } else if(val===2) {
          return '已支付'
        }else if(val===3){
          return '已取消'
        }
      },
      orderCompleteFilter(val){
        if(val===1) {
          return '未完成'
        } else if(val===2) {
          return '已完成'
        }else if(val===3){
          return '已取消'
        }
      },
      classFilter(val){
        switch (val) {
          case 1:  //待审核
            return 'waitreview';
          case 2:  //已审核
            return 'hasreview';
          case 3:  //等待
            return 'waitreview';
          case 4:  //拒绝
            return 'nopass';
          case 5:  //退班
            return 'returnClass';
        }
      },

      orderClassFilter(val){
        switch (val) {
          case 1:  //未支付
            return 'nopay';
          case 2:  //已支付
            return 'ispay';
          case 3:  //已取消
            return 'hasCancel';
        }
      },


    }
    
    
}
</script>
<style lang="less">

.btn {
  display: inline-block;
  font-size: 14px;
  color: #333;
  margin-left: 0.15rem;
}

.border {
  border: 1px solid currentColor;
  font-size: 12px;
}

.text-red {
  color: red;
  padding: 0 0.2rem;
}

.text-blue {
  color: #1a66f3;
  padding: 0 0.2rem;
}
.container-bg{
  // background-color:#ffffff;
  padding-bottom: 0.01rem;
}
.order-list{
  padding:0 5% 0.1rem 5%;
  background:#ffffff;
  margin-bottom:0.3rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  .top{
    font-size: 14px;
    color:#333333;
    display: flex;
    justify-content:space-between;
    padding: 0.4rem 0;

    p{
      color:#999999;
    }
    span{
      flex-grow:0;
      flex-shrink:0;
    }
  } 
}
.order-detail{
  border-top: 1px solid #f2f2f5;
  border-bottom: 1px solid #f2f2f5;
  text-align: left;
  padding: 0.15rem 0;
 .order-item{
    display: flex;
    justify-content:space-between;
    align-items: center;
    font-size: 12px;
    color:#999999;
    span{
      font-size: 14px;
      color:#999999;
      line-height: 0.3rem;
      margin:0.1rem 0;
    }
    .title{
      color:#333333;
      font-size: 16px;
      font-weight: 400;
      line-height: 0.5rem;
      margin:0.15rem 0;
    }
    .money{
      font-size: 14px;
      margin-left: 0.7rem;
      color:#FF3F47;
      font-weight: bold;
    }
    .waitreview{ /*待审核/等待*/
      color:#F7A121;
    }
    .hasreview{ /*已审核*/
      color:#00B262;
    }
    .nopass{ /*未通过*/
      color:#FF3F47;
    }
    .returnClass,.hasCancel{ /*退班*/
      color:#999999;
    }
    .nopay{ /*未支付*/
      color:#2E8DF4;
    }
    .ispay{ /*已支付*/
      color:#00B262;
    }
    .cancelOrder{  /*取消订单*/
      color:#FF3F47;
    }
  }
}

.bottom{
  height:0.5rem;
  line-height: 0.5rem;
  margin:0.26rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
   p{
    font-size: 14px;
    color:#999999;
  }
  .btn-box {
    display: inline-flex;
    span{
      padding:0 0.2rem;
      border:1px solid #999;
      color:#333;
      font-size: 12px;
      margin-left: 0.15rem;
    }
    .color-red{
      border:1px solid #FF3F47;
      color:#FF3F47;
    }
  }
}


</style>
